import React from 'react';
import {ForgotPasswordForm} from "../../components/session";
import {Link} from 'gatsby';
import LayoutA from "../../layouts/LayoutA";
import {Icon} from '@blueprintjs/core'

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false
    }
  }
  render() {

    const { success } = this.state;

    return (
      <LayoutA>
        <div className="LoginTemplate">
          <div className="LoginTemplate-floatingform">
            <h1 className="LoginTemplate-title">Password Reset</h1>
            {!success ? (
              <ForgotPasswordForm onSuccess={() => this.setState({success: true}) } />
            ) : (
              <div className="bp3-callout bp3-intent-primary">
                <h4 className="bp3-heading">Password Reset Email Sent</h4>
                We've sent you an email to reset your password. Please check your inbox and click the link.
              </div>
            )}
            <div className="LoginTemplate-buttonbar">
              <Link to={"/account/login"} className="LoginTemplate-buttonbar">
                <Icon icon="arrow-left" />
                <span className="pt-icon-standard pt-icon-arrow-left"/>Back to Login
              </Link>
            </div>
          </div>
        </div>
      </LayoutA>
    );
  }
}

export default ForgotPasswordPage;